export async function fetchWrapper<T = unknown>(input: RequestInfo | URL, init?: RequestInit | undefined) {
  const response = await fetch(`${process.env.NEXT_PUBLIC_API}${input}`, init);

  const data = await response.json();

  if (!response.ok) {
    throw new Error(`Error ${response.status} `, { cause: { data: data } });
  }

  return data as T;
}
