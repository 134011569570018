'use client';

import { useEffect } from 'react';

export type Segmentations = {
  chave: string;
  valor: Array<string | string[]>;
};

interface AdsEventsProps {
  segmentacoes: Segmentations[] | undefined;
}

export default function AdsSegmentations({ segmentacoes }: AdsEventsProps) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.googletag && googletag.apiReady) {
        if (window.googletag && googletag.apiReady) {
          window.googletag = window.googletag || { cmd: [] };

          const { googletag } = window;
          googletag.pubads().clearTargeting();

          googletag.cmd.push(() => {
            if (typeof segmentacoes !== 'undefined' && segmentacoes?.length > 0) {
              segmentacoes.forEach((seg: Segmentations) => {
                if (typeof seg?.valor === 'string') {
                  googletag.pubads().setTargeting(seg.chave, seg?.valor);
                } else {
                  seg?.valor?.forEach(subseg => {
                    googletag.pubads().setTargeting(seg.chave, subseg);
                  });
                }
              });
            }

            googletag.enableServices();
          }, []);
        }
      }
    }
  }, [segmentacoes]);

  return <></>;
}
