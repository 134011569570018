import React, { ImgHTMLAttributes } from 'react';
import ContentComposer, { ComponentProps } from '../ContentComposer';
import Image from 'next/image';
import { classNames } from '@/utils';

export interface CustomImageComponent extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  blurhash?: string;
  width?: number;
  height?: number;
  className?: string;
  unoptimized?: boolean;
  alt?: string;
  figcaption?: string | string[] | ComponentProps[];
  srcset?: { [key: number]: string };
}

export default function ImageComponent({
  src,
  alt,
  figcaption = undefined,
  width = undefined,
  height = undefined,
  className = undefined,
  unoptimized = true,
}: CustomImageComponent) {
  return (
    <figure
      className={classNames('w-full h-full rounded [&>div:h-full] [&>div:roudend]', className)}
      style={{ width: width || '100%', height: height || '100%' }}>
      <Image src={src} alt={alt ? alt : ''} width={width && width} height={height && height} loading="lazy" unoptimized={unoptimized} />

      {figcaption && (
        <figcaption>
          {figcaption && figcaption !== undefined && figcaption?.length > 0 && typeof figcaption === 'string'
            ? figcaption
            : Array.isArray(figcaption) &&
              figcaption?.map((figcapitem, id) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`${typeof figcapitem === 'string' ? figcapitem : figcapitem.valor}-${id}`}>
                  {figcapitem && typeof figcapitem === 'string' && figcapitem?.length > 0
                    ? figcapitem
                    : typeof figcapitem === 'object' && (
                        <ContentComposer
                          tipo={figcapitem?.tipo}
                          url={figcapitem.url}
                          as={figcapitem?.tag}
                          valor={figcapitem?.valor}
                          target={figcapitem.target}
                        />
                      )}
                </React.Fragment>
              ))}
        </figcaption>
      )}
    </figure>
  );
}
