'use client';

import { classNames } from '@/utils';
import Link from 'next/link';
import { useState } from 'react';
import ContentComposer from '../ContentComposer';

interface SEOMessageProps {
  content: any;
  url?: string;
}

export default function SEOMessage({ content, url }: SEOMessageProps) {
  const [isVisible, setVisible] = useState(false);

  const viewMoreToggle = () => {
    setVisible(old => !old);
  };

  return (
    <>
      <div
        className={classNames(
          'relative overflow-hidden text-gray-600 mb-4 [&_p]:text-sm [&_p]:leading-5 [&_p]:pb-2 [&_p_a]:underline [&_h2]:text-lg [&_h2]:pb-4 ',
          isVisible
            ? 'h-full'
            : 'h-[110px] after:absolute after:w-full after:h-[50px] after:top-[65px] after:bg-gradient-to-b after:from-transparent after:to-gray-100'
        )}>
        {content?.map((partHtml: any, index: number) => {
          return (
            <ContentComposer
              tipo={partHtml.tipo}
              as={partHtml.tag}
              url={partHtml.url}
              target={partHtml.target}
              valor={partHtml?.valor}
              atributos={partHtml?.atributos}
              key={`${partHtml.tipo}-${index}`}
            />
          );
        })}
        {url && (
          <Link scroll href={url}>
            ver mais
          </Link>
        )}
      </div>
      <button className="text-gray-600 text-sm font-normal underline mb-12" onClick={viewMoreToggle}>
        ver {!isVisible ? 'mais' : 'menos'}
      </button>
    </>
  );
}
