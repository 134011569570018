'use client';

// @refresh reset
import React from 'react';
import dynamic from 'next/dynamic';
import { AllAdOptions } from '@/lib/ads';

export interface AdGeneratorProps {
  type: AllAdOptions;
  idCountPosition?: string;
  marginBottomP?: string;
}

export function AdsGenerator({ type, idCountPosition = '', marginBottomP = '' }: AdGeneratorProps) {
  const AdsTypeGenerators = dynamic(() => import('@/components/Google/Ads/AdsTypeGenerators'), { ssr: false });
  return <AdsTypeGenerators type={type} idCountPosition={idCountPosition} marginBottomP={marginBottomP} />;
}

const AdsGeneratorMemo = React.memo(AdsGenerator, () => true);

export default AdsGeneratorMemo;
