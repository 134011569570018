/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import parse, { attributesToProps } from 'html-react-parser';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import React, { ElementType, HTMLAttributes } from 'react';

export interface ComponentProps extends HTMLAttributes<HTMLOrSVGElement> {
  as?: ElementType;
  tipo: string;
  atributos?: { nome: string; valor: string }[];
  valor?: (string | ComponentProps)[];
  target?: string;
  url?: string;
  rel?: string;
  [x: string]: any;
}

export function ContentComposer({
  as: Tag = React.Fragment,
  tipo,
  valor = undefined,
  url = undefined,
  target = undefined,
  rel = undefined,
  atributos = undefined,
  ...otherProps
}: ComponentProps) {
  const elementParser = (type: string) => {
    let ContainerTag: ElementType = React.Fragment;
    let ParentTag: ElementType = React.Fragment;
    let linkProps = {};
    const attrProps = {};

    if (type === 'link') {
      ContainerTag = 'a';
      linkProps = { href: url, rel, target };
    }
    if (type === 'lista') ContainerTag = 'li';

    if (atributos) {
      atributos.forEach(attr => {
        Object.assign(attrProps, { [attr.nome]: attr.valor });
      });
    }

    const element = valor?.map((child, index) => {
      if (typeof child === 'string')
        return (
          <ContainerTag {...linkProps} {...otherProps} {...(ContainerTag !== React.Fragment && attributesToProps(attrProps))} key={index}>
            {child}
          </ContainerTag>
        );

      return (
        <ContainerTag {...linkProps} {...otherProps} key={index}>
          <ContentComposer
            as={child?.tag}
            tipo={child?.tipo}
            valor={child?.valor}
            url={child?.url}
            target={child?.target}
            rel={child?.rel}
            atributos={child?.atributos}
          />
        </ContainerTag>
      );
    });

    return (
      <ParentTag>
        <Tag {...otherProps} {...(Tag !== React.Fragment && attributesToProps(attrProps))}>
          {element}
        </Tag>
      </ParentTag>
    );
  };

  return <>{tipo !== 'html' ? elementParser(tipo) : <>{valor && typeof valor[0] === 'string' && parse(valor[0])}</>}</>;
}

export default ContentComposer;
