import { classNames } from '@/utils';
import { ComponentPropsWithoutRef } from 'react';

export const SuperLeaderboardContainer = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className={classNames(['m-0 hidden lg:mx-auto lg:my-0 lg:flex lg:col-start-1 lg:col-end-5 ', rest.className])} {...rest}>
      {children}
    </div>
  );
};
export const RectangleContainer = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className={classNames(['flex justify-center lg:hidden', rest.className])} {...rest}>
      {children}
    </div>
  );
};
