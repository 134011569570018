import { useEffect, useRef } from 'react';

interface InfiniteScrollProps {
  fetchMore: () => void;
}

export default function InfiniteScrollComponent({ fetchMore }: InfiniteScrollProps) {
  const containerRef = useRef<any>();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        observer.disconnect();
        fetchMore();
      }
    }, options);
    containerRef.current && observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div ref={containerRef} style={{ height: '1rem', marginBottom: '1rem' }} />;
}
