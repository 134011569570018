'use client';

import { useState } from 'react';

interface OfferInformationProps {}

export default function OfferInformation() {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <span className="text-lg ml-0.5 align-top text-gray-400 cursor-pointer inline-block" onClick={handleShowModal}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
          data-icon="mdi-information-outline"
          style={{ verticalAlign: '-0.125em', transform: 'rotate(360deg)' }}>
          <path
            fill="currentColor"
            d="M11 9h2V7h-2m1 13c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m-1 15h2v-6h-2v6Z"></path>
        </svg>
      </span>
      {showModal && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-30 z-30" onClick={handleCloseModal}></div>
          <span className="text-xs leading-5 bg-white absolute p-5 w-[calc(100%-4rem)] left-8 text-left rounded-md shadow-md z-40 text-gray-600 ">
            *<strong>IMPORTANTE</strong>: fique atento à data de publicação deste anúncio, pois todos os preços e promoções estão
            <strong> sujeitos à disponibilidade de estoque e duração da oferta</strong>, que tem tempo limitado. Alterações de preço podem ocorrer a qualquer
            momento, sem prévio aviso. O preço ou valor total do produto poderá ser alterado de acordo com a localidade, considerando frete e possíveis impostos
            interestaduais. O Canaltech Ofertas atua como um veiculador de ofertas e anúncios de produtos do varejo, conforme prévia pesquisa realizada por sua
            equipe, não tendo controle sobre o preço, o processo de cobrança, faturamento e logística, tampouco sobre a duração do estoque que, a exemplo da
            qualidade do produto, são de responsabilidade única e exclusiva da loja anunciante. Para que possamos reforçar a nossa atuação enquanto veiculadores
            de ofertas, caso tenha algum problema ao acessar algum anúncio ou mesmo após a compra de algum produto, compartilhe conosco sua experiência.
          </span>
        </>
      )}
    </>
  );
}
