import { BreadcrumbItem } from '@/components/Layout/Breadcrumb';
import { OfferCardType } from '@/components/Layout/OfferCard';
import { SchemaType } from '@/components/Layout/Schemas';
import { notFound, redirect } from 'next/navigation';
import { fetchWrapper } from './fetch';
import { Segmentations } from '@/components/Google/Ads/AdsSegmentations';

interface ApiDefaultBaseType {
  schema: SchemaType;
  segmentacoes?: Segmentations[] | undefined;
  ssr?: any;
}

export interface ApiDefaultOfferListDataType {
  breadcrumb: BreadcrumbItem[];
  texto: any;
  titulo: string;
  itens: OfferCardType[];
  destaques: OfferCardType[];
  paginacao: string;
}

export interface ApiDefaultOfferListType extends ApiDefaultBaseType {
  data: ApiDefaultOfferListDataType;
}

export interface ApiDefaultOfferDetailsType extends ApiDefaultBaseType {
  data: OfferCardType;
}

type Category = {
  nome: string;
  uri: string;
  filhas: { nome: string; uri: string }[];
};

type IndiceType = {
  nome: string;
  uri: string;
  postagens: number;
};

type AlfabeticIndiceType = {
  [key: string]: IndiceType;
};

export interface ApiDefaultCategoriesListType extends ApiDefaultBaseType {
  data: Category[];
}

export interface ApiDefaultIndiceListType extends ApiDefaultBaseType {
  data: AlfabeticIndiceType;
}
const validatesResponseDataApi = async (dataResponseAPI: any, isCatch = false) => {
  // Usando o CT status que são:
  // case 0: //sucesso(200)
  // case 1: //erro no servidor(500)
  // case 2: //acesso negado(403)
  // case 3: //não encontrado(404)
  // case 4: //inconsistencia(400)
  // case 5: //metodo não permitido(405)
  // case 6: //redirect(301)
  // case 7: //redirect(301)

  const data = isCatch ? dataResponseAPI?.cause?.data : dataResponseAPI?.data;
  const status = isCatch ? dataResponseAPI?.cause?.data?.status : dataResponseAPI?.status;

  if ((!isCatch && dataResponseAPI?.status == null) || (isCatch && dataResponseAPI?.cause?.data?.status == null)) {
    if (!isCatch) return new Error('Não foi possível validar a resposta da API');
  }

  switch (status) {
    case 3:
      notFound();
    case 1:
    case 2:
    case 4:
    case 5:
      return new Error('Metodo não permitido');
    case 6:
    case 7:
      return data?.url;
    default:
      return new Error(dataResponseAPI);
  }
};

const defaultCacheRole: RequestInit['cache'] = 'default';
const defaultCacheRoleRevalidation: RequestInit['next'] = { revalidate: 60 };

const requestDataSsrApi = async (url: string, init?: RequestInit | undefined) => {
  let data: any = {};
  let ssr: any = {};

  try {
    const responseApi = await fetchWrapper<any>(url, {
      method: init?.method || 'GET',
      cache: init?.cache || defaultCacheRole,
      next: init?.next || defaultCacheRoleRevalidation,
    });
    data = responseApi.data;
    ssr = responseApi.ssr;
    await validatesResponseDataApi(responseApi, false);
  } catch (error) {
    await validatesResponseDataApi(error, true);
    Error('Error on ssr fetch');
  }

  return { ssr };
};

const requestDataListApi = async (url: string, init?: RequestInit | undefined) => {
  let data: ApiDefaultOfferListDataType = {
    texto: '',
    titulo: '',
    breadcrumb: [],
    destaques: [],
    itens: [],
    paginacao: '',
  };
  let schema: SchemaType = {};
  let ssr: any = {};
  let segmentacoes: Segmentations[] | undefined = [];

  try {
    const responseApi = await fetchWrapper<ApiDefaultOfferListType>(url, {
      method: init?.method || 'GET',
      cache: init?.cache || defaultCacheRole,
      next: init?.next || defaultCacheRoleRevalidation,
    });
    data = responseApi.data;
    schema = responseApi.schema;
    ssr = responseApi.ssr;
    segmentacoes = responseApi.segmentacoes;
    await validatesResponseDataApi(responseApi, false);
  } catch (error) {
    await validatesResponseDataApi(error, true);
    Error('Error on getOffers');
  }

  return { data, schema, segmentacoes, ssr };
};

const requestDataDetailApi = async (url: string, init?: RequestInit | undefined) => {
  let data: OfferCardType | undefined = undefined;
  let schema: SchemaType = {};
  let ssr: any = {};
  let segmentacoes: Segmentations[] | undefined = [];
  let urlRedirect: string | null | Error = null;

  try {
    const responseApi = await fetchWrapper<ApiDefaultOfferDetailsType>(url, {
      method: init?.method || 'GET',
      cache: init?.cache || defaultCacheRole,
      next: init?.next || defaultCacheRoleRevalidation,
    });
    data = responseApi.data;
    schema = responseApi.schema;
    ssr = responseApi.ssr;
    segmentacoes = responseApi.segmentacoes;

    urlRedirect = await validatesResponseDataApi(responseApi, false);
  } catch (error) {
    urlRedirect = await validatesResponseDataApi(error, true);
    if (typeof urlRedirect !== 'string') {
      Error('Error on getOffers');
    }
  } finally {
    if (typeof urlRedirect === 'string') {
      let url = new URL(urlRedirect);
      redirect(url.pathname);
    }
  }

  return { data, schema, segmentacoes, ssr };
};

const requestDataCategoriesListApi = async (url: string, init?: RequestInit | undefined) => {
  let data: Category[] = [{ nome: '', uri: '', filhas: [] }];
  let schema: SchemaType = {};
  let ssr: any = {};
  let segmentacoes: Segmentations[] | undefined = [];

  try {
    const responseApi = await fetchWrapper<ApiDefaultCategoriesListType>(url, {
      method: init?.method || 'GET',
      cache: init?.cache || defaultCacheRole,
      next: init?.next || defaultCacheRoleRevalidation,
    });
    data = responseApi.data;
    schema = responseApi.schema;
    ssr = responseApi.ssr;
    segmentacoes = responseApi.segmentacoes;

    await validatesResponseDataApi(responseApi, false);
  } catch (error) {
    await validatesResponseDataApi(error, true);
    Error('Error on getOffers');
  }

  return { data, schema, segmentacoes, ssr };
};

const requestDataIndicesListApi = async (url: string, init?: RequestInit | undefined) => {
  let data: AlfabeticIndiceType = {};
  let schema: SchemaType = {};
  let ssr: any = {};
  let segmentacoes: Segmentations[] | undefined = [];

  try {
    const responseApi = await fetchWrapper<ApiDefaultIndiceListType>(url, {
      method: init?.method || 'GET',
      cache: init?.cache || defaultCacheRole,
      next: init?.next || defaultCacheRoleRevalidation,
    });
    data = responseApi.data;
    schema = responseApi.schema;
    ssr = responseApi.ssr;
    segmentacoes = responseApi.segmentacoes;

    await validatesResponseDataApi(responseApi, false);
  } catch (error) {
    await validatesResponseDataApi(error, true);
    Error('Error on getOffers');
  }

  return { data, schema, segmentacoes, ssr };
};

export { validatesResponseDataApi, requestDataSsrApi, requestDataDetailApi, requestDataListApi, requestDataCategoriesListApi, requestDataIndicesListApi };
