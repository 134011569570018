'use client';

import { requestDataListApi } from '@/services/api';
import { Fragment, useState } from 'react';
import OfferCard, { OfferCardType } from '../OfferCard';
import LoadingSkeletonOfferCard from '@/components/Layout/OfferCard/loading';
import InfiniteScrollComponent from '../ObserverInfiniteScroll';
import OfferInformation from '../OfferInformation';
import Title from '@/components/Title/Title';
import { AdsContains, listingRearranger } from '../Rearranger';
import { SuperLeaderboardContainer } from '@/components/Google/Ads/styles';
import React from 'react';
import dynamic from 'next/dynamic';
import AdsGeneratorMemo from '@/components/Google/Ads/AdsTypeGeneratorsMemo';

interface OffersCardListProps {
  title?: string;
  initialDataPosts?: (OfferCardType | AdsContains)[];
  initialPagination?: string;
  customPathPagination?: string;
  showAds?: boolean;
}

export default function OffersCardList({ title = '', initialDataPosts = [], initialPagination = '', customPathPagination = '' }: OffersCardListProps) {
  const [dataPosts, setDataPosts] = useState<(OfferCardType | AdsContains)[]>(initialDataPosts);
  const [paginationCode, setPaginationCode] = useState(initialPagination);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldStopPagination, setShouldStopPagination] = useState(initialDataPosts?.length < 12);

  async function getOffers(paginationCode: string, customPathPagination = '') {
    const { data } = await requestDataListApi(
      (customPathPagination && customPathPagination?.length > 1 ? customPathPagination : 'paginacao/') + paginationCode + '/'
    );
    return { data };
  }

  const getMorePosts = () => {
    setIsLoading(true);
    getOffers(paginationCode, customPathPagination)
      .then(response => {
        if (response.data.itens && response.data.itens?.length > 0) {
          const countOldPostsWithoutAds = dataPosts.filter(item => !('ads' in item)).length;
          const content = [...response.data.itens];
          const dataItensNew = listingRearranger({ content: content, existingContent: countOldPostsWithoutAds });

          setDataPosts([...dataPosts, ...dataItensNew]);
          setPaginationCode(response.data.paginacao && response.data.paginacao);
          setIsLoading(false);
        }
        if (!response.data.itens || (response.data.itens && response.data.itens?.length < 12)) {
          setShouldStopPagination(true);
        }
      })
      .catch(error => error);
  };

  return (
    <section className="relative">
      <Title>
        {title}
        <OfferInformation />
      </Title>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
        <OffersCardListSkeleton list={dataPosts} />
        {dataPosts?.length > 0 && !isLoading && <InfiniteScrollComponent fetchMore={getMorePosts} />}
        {isLoading &&
          !shouldStopPagination &&
          Array(8)
            .fill(0)
            .map((_value, idx) => {
              return <LoadingSkeletonOfferCard key={`skeleton-${idx}`} />;
            })}
      </div>
    </section>
  );
}

type OffersCardListSkeletonProps = {
  list: (OfferCardType | AdsContains)[];
};

function OffersCardListSkeleton({ list }: OffersCardListSkeletonProps) {
  return (
    <>
      {list.map((item, index) => (
        <Fragment key={index}>
          {!('ads' in item) && <OfferCard props={item} />}
          {'ads' in item && item.mobile && <AdsGeneratorMemo type="cto/rectangle/main/listing" />}
          {'ads' in item && !item.mobile && (
            <SuperLeaderboardContainer>
              <AdsGeneratorMemo type="cto/superLeaderboard/main/listing" />
            </SuperLeaderboardContainer>
          )}
        </Fragment>
      ))}
    </>
  );
}
