export default function Loading() {
  return (
    <div className="bg-white rounded animate-pulse w-[345px] sm:w-full justify-self-center ">
      <div className="block py-4 mb-[-13px] border-b border-gray-200">
        <svg
          className=" text-gray-200 dark:text-gray-600 block w-[180px] h-[180px] mx-auto p-4"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 640 512">
          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
        </svg>
      </div>
      <div className="px-4 pb-4 h-[268px] relative">
        <div className="flex items-center justify-between h-[26px] mb-4 tags-post">
          <div className="flex items-center h-auto bg-white rounded tag-contador">
            <div className="bg-gray-200 rounded dark:bg-gray-700 mb-2.5rounded-sm block w-[24px] h-[24px]"></div>
          </div>
          <div className="flex items-center flex-grow-1 justify-end tags-adicionais grow">
            <div className="h-[26px] w-[42px] rounded px-2 text-[0.6875rem] leading-6 font-semibold bg-gray-200 dark:bg-gray-700"></div>
          </div>
        </div>
        <div className="text-gray-900">
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        </div>
        <div className="mt-2 text-sm overflow-hidden relative max-h-[65px] line-clamp-3">
          <div className="text-sm leading-6 text-gray-700 block">
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
          </div>
        </div>

        <div className="absolute bottom-2 w-[calc(100%_-_32px)] text-gray-900 text-xs font-semibold inline-block no-underline h-[20px] leading-8 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}
