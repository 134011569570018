'use client';

import { useState } from 'react';

type ExpirationCounterProps = {
  id: number;
  endDate: string;
};

export default function ExpirationCounter({ id, endDate }: ExpirationCounterProps) {
  const [timer, setTimer] = useState('');

  const atualizarCronometro = () => {
    let [day, month, year, hour, minute, second]: number[] = endDate.split(/[\s/:\-]+/).map(Number);
    var dataAlvo = new Date(year, month - 1, day, hour, minute, second);
    var dataAtual = new Date();

    var diferenca = dataAlvo.getTime() - dataAtual.getTime();

    var segundos = Math.floor(diferenca / 1000);
    var minutos = Math.floor(segundos / 60);
    var horas = Math.floor(minutos / 60);
    var dias = Math.floor(horas / 24);

    if (segundos % 60 >= 0 && segundos < 60 * 60 * 24 * 3) {
      setTimer(`${horas.toString().padStart(2, '0')}:${(minutos % 60).toString().padStart(2, '0')}:${(segundos % 60).toString().padStart(2, '0')}`);
    } else {
      setTimer('');
    }
  };

  let intervalTimer;

  if (intervalTimer) {
    clearInterval(intervalTimer);
  }

  intervalTimer = setInterval(atualizarCronometro, 1000);

  return (
    <>
      {timer !== '' && (
        <div className="px-2 bg-white">
          <span className="text-gray-800 text-sm font-semibold" data-expiration={endDate}>
            {timer}
          </span>
        </div>
      )}
    </>
  );
}
