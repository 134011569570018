export interface AdsContains {
  ads: boolean;
  mobile: boolean;
}

const adsContainsMobile: AdsContains = {
  ads: true,
  mobile: true,
};

const adsContainsDesktop: AdsContains = {
  ads: true,
  mobile: false,
};

interface listingRearrangerProps<Type> {
  content: Type[];
  existingContent: number;
  hasNoAds?: boolean;
  sizeMobile?: number;
  sizeDesktop?: number;
}

export function listingRearranger<Type>({ content, existingContent, hasNoAds = false, sizeMobile = 3, sizeDesktop = 8 }: listingRearrangerProps<Type>) {
  const result: (AdsContains | Type)[] = [];
  const indexContinue = existingContent;
  content.forEach((element: Type, index: number) => {
    result.push(element);
    const currentIndex = index + 1 + indexContinue;
    if (currentIndex % sizeDesktop === 0 && currentIndex >= sizeDesktop && !hasNoAds) result.push(adsContainsDesktop);
    if (currentIndex % sizeMobile === 0 && currentIndex >= sizeMobile && !hasNoAds) result.push(adsContainsMobile);
  });

  return result as (Type | AdsContains)[];
}

const Rearranger = { listingRearranger };

export default Rearranger;
