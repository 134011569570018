'use client';

import useInterval from '@/hooks/useInterval';
import { requestDataListApi } from '@/services/api';
import { useState } from 'react';

type ButtonRefreshingProps = {
  firstItemId: string;
  firstHighlighId: string;
};

export default function ButtonRefreshing({ firstItemId, firstHighlighId }: ButtonRefreshingProps) {
  const [refreshing, setRefreshing] = useState(true);
  const [firstHighligh, setFirstHighligh] = useState(firstHighlighId);
  const [firstItem, setFirstItem] = useState(firstItemId);
  const [showButtonNewOffer, setShowButtonNewOffer] = useState(false);

  async function verifyOffers() {
    const { data } = await requestDataListApi('listagem/');

    setFirstHighligh(data.destaques && data.destaques[0]?.id?.toString());
    setFirstItem(data.itens && data.itens[0]?.id?.toString());
  }

  const handleTouchNewOffers = (toogleConditions: boolean) => {
    if (!toogleConditions) {
      setShowButtonNewOffer(true);
    }
    setRefreshing(toogleConditions);
  };

  const validateItens = () => {
    const firstIdentifier = document?.querySelectorAll('[data-identifier]')[0]?.getAttribute('data-identifier');
    const firstIdentifierHighlight = document?.querySelectorAll('[data-identifierhighlight]')[0]?.getAttribute('data-identifierhighlight');

    return firstIdentifierHighlight == firstHighligh && firstIdentifier == firstItem;
  };

  const validateProcess = async () => {
    if (refreshing) {
      await verifyOffers();
      let isEqual = await validateItens();
      handleTouchNewOffers(isEqual && !!document?.querySelectorAll('[data-identifier]')[0]);
    }
  };

  useInterval(() => {
    validateProcess();
  }, 120000);

  return (
    <>
      {showButtonNewOffer && (
        <div className="wrapper-novas-ofertas fixed top-[60px] left-0 right-0 z-10 text-center animate-show-from-top">
          <a
            href="/"
            className="button inline-block m-auto items-center h-[40px] w-[40px] leading-9 pr-[9px]  pl-[9px] overflow-hidden cursor-pointer rounded-3xl text-white bg-[#4da92c] text-left animate-animation-expand houver:animate-pulse"
            style={{ animationDelay: '1000ms' }}>
            <div className="icon inline-block align-middle mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
                className="iconify h-[22px] w-[22px] "
                data-icon="mdi-refresh"
                style={{ verticalAlign: 'middle !important', transform: 'rotate(360deg)' }}>
                <path
                  fill="currentColor"
                  d="M17.65 6.35A7.958 7.958 0 0 0 12 4a8 8 0 0 0-8 8a8 8 0 0 0 8 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0 1 12 18a6 6 0 0 1-6-6a6 6 0 0 1 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35Z"></path>
              </svg>
            </div>
            <span className="text-sm font-semibold transition-all duration-300 ease-out overflow-hidden">Temos novas ofertas! Clique aqui</span>
          </a>
        </div>
      )}
    </>
  );
}
